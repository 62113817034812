import React, { useState } from "react";
import { Box, Grid, Paper, styled, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { slideInRight } from "../../animation";

const ProductActionsWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== "show",
})(({ show, theme }) => ({
  display: show ? "visible" : "none",
  position: "absolute",
  right: 0,
  width: "100%",
  animation: show &&
    `${slideInRight} 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both`,
  [theme.breakpoints.down("lg")]: {
    display: "inherit",
    position: "inherit",
  },
}));

const TechCard = (
  {
    title,
    description,
    imageUrl,
    url,
    xs,
    md,
    newTab,
    lg,
    maxImageHeight = "259px",
  },
) => {
  const history = useNavigate();

  const [showOptions, setShowOptions] = useState(false);

  const handleMouseEnter = (funcs) => {
    funcs(true);
  };
  const handleMouseLeave = (funcs) => {
    funcs(false);
  };

  const handleurls = () => {
    if (url === undefined || url === "") {
      window.open(newTab, "_blank");
    } else {
      history(url);
    }
  };

  return (
    <Grid
      item
      sm={md}
      xs={xs}
      lg={lg}
      onClick={handleurls}
      style={{ cursor: "pointer" }}
      onMouseEnter={(e) => handleMouseEnter(setShowOptions)}
      onMouseLeave={(e) => handleMouseLeave(setShowOptions)}
    >
      <Paper elevation={12} sx={{ padding: "15px",height:"100%" }}>
        <Typography
          variant="h6"
          sx={{ position: "relative", height: "40px", width: "100%" }}
        >
          <ProductActionsWrapper show={showOptions}>
            {title}
          </ProductActionsWrapper>
        </Typography>
        <Box sx={{ marginTop: "15px", maxWidth: "100%" }}>
          <img
            style={{ maxWidth: "100%", maxHeight: maxImageHeight }} alt={title}
            src={imageUrl}
          >
          </img>
        </Box>
        <Typography
          sx={{ position: "relative", height: "60px", width: "100%", mt: 1 }}
        >
          <ProductActionsWrapper show={showOptions}>
            {description}
          </ProductActionsWrapper>
        </Typography>
      </Paper>
    </Grid>
  );
};

export default TechCard;
