import React from 'react';
import { Grid, Typography, Divider, SvgIcon, Box, List, ListItem, styled, ListItemButton, ListItemIcon, ListItemText, Switch } from '@mui/material';
import image from '../images/circle-cropped.png';
import { Person, PermContactCalendar, Computer, Feed, Twitter, Info, Home, Article, Group, Storefront, Brightness6, LinkedIn, GitHub, YouTube } from '@mui/icons-material';
import { NavLink,Link } from "react-router-dom";
import ModalPortfolio from './modal';
import Resume from '../images/Resume.pdf';
import './ui.css';

const Profiles = (props) => {
  const [open, setOpen] = React.useState(false);
  const switchStatus = props.switchStatus === "dark" ? true : false;


  const ListItemsButtonNoPadding = styled(ListItemButton)({
    padding: 0,
    margin: 0,
  });

  const ItemListIconMod = styled(ListItemIcon)({
    minWidth: "35px",
  });

  const LinkNoDecoration = styled(NavLink)({
    color: "inherit",
    textDecoration: "none",
  });

  const LinkNoDecorationN = styled(Link)({
    color: "inherit",
    textDecoration: "none",
  });

  const Urls = ({ setTheme }) => {

    const links = [
      {
        url: "/",
        text: "About Me",
        icon: Person,
      },
            {
                url: "/index",
                text: "Portfolio",
                icon: Computer,
            },
      {
        url: Resume,
        text: "Resume",
        icon: Feed,
      },
      {
        url: "/contact",
        text: "Contact",
        icon: PermContactCalendar,
      },
      {
        url: "/about",
        text: "About Site",
        icon: Info,
      },

    ];

    return <List sx={{ textAlign: "left" }}>
      {links.map(item => {
        if (item.url === "/portfolio") {
          return <LinkNoDecoration key={item.url} to="/" onClick={() => { setOpen(!open) }} style={{ textDecoration: "none", color: "inherit" }} className={isActive =>"nav-link" + (!isActive ? " unselected" : "")}>
            <ListItem disablePadding>
              <ListItemsButtonNoPadding component="div">
                <ItemListIconMod>
                  <SvgIcon component={item.icon} sx={{ color: "text.primary" }}></SvgIcon>
                </ItemListIconMod>
                <ListItemText primary={item.text} />
              </ListItemsButtonNoPadding>
            </ListItem>
          </LinkNoDecoration>

        }
        if (item.text === "Resume") {
          return <LinkNoDecorationN key={item.url} to="./" onClick={() => { window.open(Resume, '_blank', 'noopener, noreferrer'); }}>
            <ListItem disablePadding>
              <ListItemsButtonNoPadding component="div">
                <ItemListIconMod>
                  <SvgIcon component={item.icon} sx={{ color: "text.primary" }}></SvgIcon>
                </ItemListIconMod>
                <ListItemText primary={item.text} />
              </ListItemsButtonNoPadding>
            </ListItem>

          </LinkNoDecorationN>
        }
        return <LinkNoDecoration to={item.url} key={item.url} >
          <ListItem disablePadding>
            <ListItemsButtonNoPadding component="div">
              <ItemListIconMod>
                <SvgIcon component={item.icon} sx={{ color: "text.primary" }}></SvgIcon>
              </ItemListIconMod>
              <ListItemText primary={item.text} />
            </ListItemsButtonNoPadding>
          </ListItem>
        </LinkNoDecoration>

      })}
    </List>
  }


  const openNewTab = (url) => {
    window.open(url, "_blank");
  };



  return <Box
    sx={{
      maxWidth: "280px",
      minWidth: "280px",

    }}
  >
    <Box
      sx={{
        backgroundColor: "text.disabled",

        minWidth: "280px",
        maxWidth: "280px",
        position: "fixed",
        top: 0,
        bottom: 0,
        overflowY: "auto"


      }}
    >
      <Grid container style={{
        width: "100%",
      }} direction="column" justifyContent="flex-start"
        alignItems="center">
        <ModalPortfolio open={open} setopen={setOpen} />
        <Grid item style={{ textAlign: "center", width: "max-content", maxHeight: "50px", margin: "10px 0px 10px 0px" }} >
          <Typography variant="h4" component="h3" style={{ margin: "auto" }} className="navlink">
            Jason Ancona
          </Typography>
        </Grid>
        <Grid item style={{ textAlign: "center", height: "max-content", verticalAlign: "top", paddingBottom: "8px" }}>
          <img src={image} style={{ width: "160px", height: "160px", padding: "4px", margin: "auto", backgroundColor: "black", borderRadius: "360px" }}></img>
        </Grid>
        <Divider style={{ width: "80%", height: "max-content" }} />
        <Grid item style={{ textAlign: "center", height: "max-content" }} >
          <Typography variant="p" component="h4" style={{ margin: "auto", fontWeight: "normal", padding: "20px" }}>
            Hello, my name is Jason Ancona and i'm a developer specialized in MERN stack. Welcome to my personal site!
          </Typography>
        </Grid>
        <Grid item container spacing={2} alignItems="center" style={{ textAlign: "center", width: "100%", margin: "0", height: "max-content", paddingBottom: "10px" }} justifyContent="center">
          <Grid item style={{ textAlign: "center" }} onClick={() => { openNewTab("https://www.linkedin.com/in/jason-ancona-baa4a6247/") }}>
            <SvgIcon style={{ backgroundColor: "white", padding: "5px", cursor: "pointer", borderRadius: "360px", fontSize: "2em" }} color="secondary" className="icon-online" component={LinkedIn}></SvgIcon>
          </Grid>
          <Grid item style={{ textAlign: "center" }} onClick={() => { openNewTab("https://github.com/jasondruid") }}>
            <SvgIcon style={{ backgroundColor: "white", cursor: "pointer", padding: "5px", borderRadius: "360px", fontSize: "2em" }} color="secondary" className="icon-online" component={GitHub}></SvgIcon>
          </Grid>
          <Grid item style={{ textAlign: "center" }} onClick={() => { openNewTab("https://www.youtube.com/channel/UCBPQwFwk34rnIgO177flUxA") }}>
            <SvgIcon style={{ backgroundColor: "white", cursor: "pointer", padding: "5px", borderRadius: "360px", fontSize: "2em" }} color="secondary" className="icon-online" component={YouTube}></SvgIcon>
          </Grid>
        </Grid>
        <Divider style={{ width: "80%" }} />
        <Urls />
        <Divider style={{ width: "80%" }} />
        <List>
          <ListItem disablePadding>
            <ListItemButton component="a" href="#simple-list" sx={{ paddingTop: 0, paddingBottom: 0, marginBottom: 0, marginTop: 0, }} onClick={() => props.switchTheme(props.switchStatus)} >
              <ItemListIconMod sx={{ paddingTop: 0, marginTop: 0, }}>
                <Brightness6 sx={{ color: "text.primary" }} />
              </ItemListIconMod>
              <ListItemText primary="Dark Mode" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding onClick={() => props.switchTheme(props.switchStatus)} >
            <ListItemButton component="a" href="#simple-list" sx={{ paddingTop: 0, paddingBottom: 0, marginBottom: 0, marginTop: 0, textAlign: "center" }}>
              <ListItemText primary={<Switch color="secondary" checked={switchStatus} />} />
            </ListItemButton>
          </ListItem>
        </List>
      </Grid>
    </Box>
  </Box>






}


export default Profiles;