import React from "react";
import {
  AppBar,
  Box,
  CardContent,
  Collapse,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  styled,
  SvgIcon,
  Switch,
  Toolbar,
  Typography,
} from "@mui/material";
import {

  Brightness6,

  Menu,

  Person,
  LinkedIn,
  YouTube,
  GitHub,
  PermContactCalendar,
  Info,
  Feed,
Work,
} from "@mui/icons-material";
import image from "../images/circle-cropped.png";
import Resume from '../images/Resume.pdf';
import { NavLink, Link } from "react-router-dom";
import ModalPortfolio from './modal';
import './ui.css';


const MobileNav = (props) => {
  const [expanded, setExpanded] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const StyledToolbar = styled(Toolbar)({
    justifyContent: "space-between",
  });

  const ListItemsButtonNoPadding = styled(ListItemButton)({
    padding: 0,
    margin: 0,
    paddingTop: "3px",
    paddingBottom: "3px",
  });

  const ItemListIconMod = styled(ListItemIcon)({
    minWidth: "35px",
  });

  const LinkNoDecoration = styled(NavLink)({
    color: "inherit",
    textDecoration: "none",
  });

  const LinkNoDecorationN = styled(Link)({
    color: "inherit",
    textDecoration: "none",
  });


  const switchStatus = props.switchStatus === "dark" ? true : false;




  const MenuItems = () => {



    const links = [
      {
        url: "/",
        text: "About Me",
        icon: Person,
      },
   /* {
        url: "/portfolio",
        text: "Portfolio",
        icon: Computer,
    }*/,
      {
        url: Resume,
        text: "Resume",
        icon: Feed,
      },
      {
        url: '/index',
        text: "Portfolio",
        icon: Work

      },
      {
        url: "/contact",
        text: "Contact",
        icon: PermContactCalendar,
      },
      {
        url: "/about",
        text: "About Site",
        icon: Info,
      },

    ];


    return <List sx={{ width: "100%", textAlign: "left" }}>
      {links.map(item => {
        if (item.url === "/portfolio") {
          return <LinkNoDecoration to="#" onClick={() => { setOpen(!open) }} style={{ textDecoration: "none", color: "inherit" }}>
            <ListItem disablePadding>
              <ListItemsButtonNoPadding component="a">
                <ItemListIconMod>
                  <SvgIcon component={item.icon} sx={{ color: "text.primary" }}></SvgIcon>
                </ItemListIconMod>
                <ListItemText primary={item.text} />
              </ListItemsButtonNoPadding >
            </ListItem>
          </LinkNoDecoration>

        }
        if (item.text === "Resume") {
          return <LinkNoDecorationN to="#" onClick={() => { window.open(Resume, '_blank', 'noopener,noreferrer'); }}>
            <ListItem disablePadding>
              <ListItemsButtonNoPadding component="a">
                <ItemListIconMod>
                  <SvgIcon component={item.icon} sx={{ color: "text.primary" }}></SvgIcon>
                </ItemListIconMod>
                <ListItemText primary={item.text} />
              </ListItemsButtonNoPadding >
            </ListItem>

          </LinkNoDecorationN>
        }
        return <LinkNoDecoration to={item.url} onClick={() => setExpanded(false)} >

          <ListItem disablePadding>
            <ListItemsButtonNoPadding component="a">
              <ItemListIconMod>
                <SvgIcon component={item.icon} sx={{ color: "text.primary" }}></SvgIcon>
              </ItemListIconMod>
              <ListItemText primary={item.text} />
            </ListItemsButtonNoPadding >
          </ListItem>
        </LinkNoDecoration>

      })}
    </List>




  }


  const openNewTab = (url) => {
    window.open(url, "_blank");
  };




  return (
    <AppBar sx={{ position: "static" }}>
      <ModalPortfolio open={open} setopen={setOpen} />
      <Toolbar>
        <Stack style={{ width: "100%" }}>
          <Stack
            direction="row"
            alignItems="center"
            sx={{ justifyContent: "space-between", display: "flex" }}
          >
            <Box sx={{ flexGrow: 1 }}>
              <IconButton
                disableRipple
                onClick={(e) => setExpanded(!expanded)}
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
              >
                <Menu fontSize="large" />
              </IconButton>
            </Box>
            <Box sx={{ flexGrow: 3 }}>
              <Typography
                style={{ textAlign: "center", fontWeight: "bold" }}
                variant="h5"
              >
                Jason Ancona
              </Typography>
            </Box>
            <Box sx={{ flexGrow: 1 }}>
              <IconButton
                disableRipple
                onClick={(e) => setExpanded(!expanded)}
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
              >
                <SvgIcon fontSize="large" />
              </IconButton>
            </Box>
          </Stack>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Collapse
              in={expanded}
              timeout="auto"
              unmountOnExit
            >
              <Stack justifyContent="center" alignItems="center" spacing={2}>
                <img
                  src={image}
                  style={{
                    height: "160px",
                    width: "160px",
                    padding: "4px",
                    margin: "auto",
                    backgroundColor: "black",
                    borderRadius: "360px",
                  }}
                >
                </img>
                <Typography>
                  Hello, my name is Jason Ancona and i'm a developer specialiced in MERN stack. Welcome to my personal site!
                </Typography>
                <Grid item container spacing={2} alignItems="center" style={{ textAlign: "center", width: "100%", margin: "0", height: "max-content", paddingBottom: "10px" }} justifyContent="center">
                  <Grid item style={{ textAlign: "center" }} onClick={() => { openNewTab("https://www.linkedin.com/in/jason-ancona-baa4a6247/") }}>
                    <SvgIcon style={{ backgroundColor: "white", padding: "5px", cursor: "pointer", borderRadius: "360px", fontSize: "2em" }} color="secondary" className="icon-online" component={LinkedIn}></SvgIcon>
                  </Grid>
                  <Grid item style={{ textAlign: "center" }} onClick={() => { openNewTab("https://github.com/jasondruid") }}>
                    <SvgIcon style={{ backgroundColor: "white", cursor: "pointer", padding: "5px", borderRadius: "360px", fontSize: "2em" }} color="secondary" className="icon-online" component={GitHub}></SvgIcon>
                  </Grid>
                  <Grid item style={{ textAlign: "center" }} onClick={() => { openNewTab("https://www.youtube.com/channel/UCBPQwFwk34rnIgO177flUxA") }}>
                    <SvgIcon style={{ backgroundColor: "white", cursor: "pointer", padding: "5px", borderRadius: "360px", fontSize: "2em" }} color="secondary" className="icon-online" component={YouTube}></SvgIcon>
                  </Grid>
                </Grid>
                <Divider
                  sx={{ width: "100%", mr: 3, ml: 3, height: "1.5px", backgroundColor: "text.primary" }}
                />
                <MenuItems />
                <Divider
                  sx={{ width: "100%", mr: 3, ml: 3, height: "1.5px", backgroundColor: "text.primary" }}
                />
                <List>
                  <ListItem disablePadding onClick={() => props.switchTheme(props.switchStatus)}>
                    <ListItemButton component="a" href="#simple-list" sx={{ paddingTop: 0, paddingBottom: 0, marginBottom: 0, marginTop: 0, }}>
                      <ItemListIconMod sx={{ paddingTop: 0, marginTop: 0, }}>
                        <Brightness6 sx={{ color: "text.primary" }} />
                      </ItemListIconMod>
                      <ListItemText primary="Dark Mode" />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding onClick={() => props.switchTheme(props.switchStatus)}>
                    <ListItemButton component="a" href="#simple-list" sx={{ paddingTop: 0, paddingBottom: 0, marginBottom: 0, marginTop: 0, textAlign: "center" }}>
                      <ListItemText primary={<Switch color="secondary" checked={switchStatus} />} />
                    </ListItemButton>
                  </ListItem>
                </List>
              </Stack>
            </Collapse>
          </Box>
        </Stack>
      </Toolbar>
    </AppBar>
  );
};



export default (MobileNav);
