import React from 'react';
import { Grid, Box, Typography} from '@mui/material';
import TechCard from './techCard';
import MuiIcon from '../../images/mui.png';
import RoutingIcon from '../../images/reactRouting.png';
import FormikIcon from '../../images/formik.png';
import ToastifyIcon from '../../images/toastify.png';
import YupIcon from '../../images/yup.jpg';
import ReduxIcon from '../../images/reduxsaga.png';


const AboutWebsite = () => {


    const techArray = [
        {
            name: "Material UI",
            description: "A suite of UI tools to ship new features faster with an awesome UI",
            image: MuiIcon,
            url:'',
            xs:12,
            lg:4,
            md:6,
            tab:"https://mui.com/"
        },
        {
            name: "React Router",
            description: "React Router is the standard routing library for React.",
            image: RoutingIcon,
            url:'',
            xs:12,
            lg:4,
            md:6,
            tab:"https://reactrouter.com/",
        },
        {
            name: "Formik",
            description: "Handles How the form state is manipulated, including validation, error and submition.",
            image: FormikIcon,
            url:'',
            xs:12,
            lg:4,
            md:6,
            tab:'https://formik.org/',
        },
        {
            name: "YUP",
            description: "Yup is schema builder for value parsing and validation.",
            image: YupIcon,
            url:'',
            xs:12,
            lg:4,
            md:6,
            tab:"https://www.npmjs.com/package/yup",
        },
        {
            name: "Toastify",
            description: "allows notifications to any React app with ease.",
            image: ToastifyIcon,
            url:'',
            xs:12,
            lg:4,
            md:6,
            tab:"https://fkhadra.github.io/react-toastify/introduction",
        },
        
        {
            name: "Redux & Redux Saga",
            description: "A library which aids in the task of managing and centralizing application state",
            image: ReduxIcon,
            url:'',
            xs:12,
            lg:4,
            md:6,
            tab:"https://redux-saga.js.org/",
        },
    ];



    return (<Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", p: 2 }}>
        <Box sx={{ width: "100%",maxWidth:"1800px", textAlign: "center", padding: { lg: 7, xs: 1, } }}>
            <Typography sx={{ paddingBottom: "30px" }}>You can click on any of them to know more about</Typography>
            <Grid container spacing={3} >


                {techArray.map(item => <TechCard title={item.name} description={item.description} imageUrl={item.image} url={item.url} xs={item.xs} md={item.md} lg={item.lg} maxImageHeight="150px" newTab={item.tab}/>)}

          
            </Grid>
            <Typography style={{ margin: "auto", paddingTop: "30px", maxWidth: "700px", textAlign: "left" }}> </Typography>
        </Box>
    </Box>)
}

export default AboutWebsite