import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Link } from "react-router-dom";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/index';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link to="#" color="inherit" href="https://mui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();

const SignIn = (props) => {
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get('email'),
      password: data.get('password'),
    });
  };


  const formik = useFormik({

    initialValues: {
      name: '',
      email: '',
      password: '',
      verifypassword: '',
    },

    validationSchema: Yup.object({
      name: Yup.string()
        .min(3, 'Must be at least 3 characters long')
        .required('This field must not be empty')
        .ensure(),
      email: Yup.string()
        .email()
        .required('This field must not be empty')
        .ensure(),
      password: Yup.string()
        .min(3, 'Must be at least 3 characters long')
        .required('This field must not be empty')
        .ensure(),
      verifypassword: Yup.string()
        .required('This field must not be empty')
        .oneOf([Yup.ref('password'), null], 'Passwords must match'),

    }),

    onSubmit: values => {

      if (values.email && values.password, values.name, values.verifypassword) {
        // PostData(values);
        props.onLogin({ email: values.email, password: values.password,name:values.name,verifypassword:values.verifypassword });
      }



      else {
        toast.error("There is an error with data", {
          position: toast.POSITION.TOP_RIGHT
        });
      }

    },
  });


  return (

    <React.Fragment>
      <ToastContainer />
      <CssBaseline />
      <Grid container style={{ height: "100%" }} justifyContent="center"
        alignItems="center">
        <Grid item xs={11} md={6}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <HowToRegIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign Up
            </Typography>
            <Box component="form" onSubmit={formik.handleSubmit} noValidate sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="name"
                label="Name"
                name="name"
                autoComplete="name"
                autoFocus
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
                error={formik.touched.name && formik.errors.name ? true : false}
                helperText={formik.touched.name && formik.errors.name ?
                  formik.errors.name
                  : null}

              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                error={formik.touched.email && formik.errors.email ? true : false}
                helperText={formik.touched.email && formik.errors.email ?
                  formik.errors.email
                  : null}

              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
                error={formik.touched.password && formik.errors.password ? true : false}
                helperText={formik.touched.password && formik.errors.password ?
                  formik.errors.password
                  : null}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="verifypassword"
                label="Repeat Password"
                type="password"
                id="verifypassword"
                autoComplete="verifypassword"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.verifypassword}
                error={formik.touched.verifypassword && formik.errors.verifypassword ? true : false}
                helperText={formik.touched.verifypassword && formik.errors.verifypassword ?
                  formik.errors.verifypassword
                  : null}
              />
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign In
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link to="#" variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  <Link to="/portfolio" variant="body2">
                    {"Do you already have an account?"}
                  </Link>
                </Grid>
              </Grid>
            </Box>
            <Copyright sx={{ mt: 8, mb: 4 }} />
          </Box>
        </Grid>
      </Grid>

    </React.Fragment>
  );
}


const mapStateToProps = state => {
  return {
        type: state.request.responseType,
        data:state.request.data,
        loading:state.request.loading,
        status:state.request.status,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onLogin :(message) =>{dispatch(actions.startRegister(message));},
  };
};

export default connect(mapStateToProps,mapDispatchToProps)(SignIn);
