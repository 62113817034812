export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties
    };
};

export const checkValidity = ( value, rules ) => {
    let isValid = true;
    if ( !rules ) {
        return true;
    }

    if ( rules.required ) {
        isValid = value.trim() !== '' && isValid;
    }

    if ( rules.minLength ) {
        isValid = value.length >= rules.minLength && isValid
    }

    if ( rules.maxLength ) {
        isValid = value.length <= rules.maxLength && isValid
    }

    if ( rules.isEmail ) {
        const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        isValid = pattern.test( value ) && isValid
    }

    if ( rules.isNumeric ) {
        const pattern = /^\d+$/;
        isValid = pattern.test( value ) && isValid
    }

    return isValid;
}

export const filterByFieldNotNullOrFalse = (array, fieldName) =>{
    
    return array.filter(object => {
            if(!object.hasOwnProperty(fieldName)) return false;
            if (object.fieldName != '' || object.fieldName != false){
                return true;
            }
            else{
                return false;
            }
        } 
    );
}


export const filterByFieldValue = (array, fieldName, fieldValue) =>{
    return array.filter(object => object.hasOwnProperty(fieldName) && object[fieldName] == fieldValue);
}

export const filterByTextAproximation = (array,fieldName,fieldValue) =>{
    if(fieldValue == null || fieldValue == '')  return array;
    
    if(fieldName == 'username' ) return array.filter(object => object.hasOwnProperty('user') && object.user[fieldName] != null && object.user[fieldName].toLowerCase().includes(fieldValue.toLowerCase()) );
    if(fieldName == 'fullname' ) return array.filter(object => object.hasOwnProperty('user') && object.user.last_name != null && object.user.first_name.concat(' ',object.user.last_name).toLowerCase().includes(fieldValue.toLowerCase()));
   
  return array.filter(object => object.hasOwnProperty(fieldName) && object[fieldName] != null && object[fieldName].toLowerCase().includes(fieldValue) );
}


