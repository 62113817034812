import React from 'react';
import { Grid, Box, Typography } from '@mui/material';
import reactImage from '../../images/react.png';
import nodeImage from '../../images/nodejs.png';
import TechCard from './techCard';


const AboutWebsite = () => {



    const mainTechnologies = [{
        title: "React",
        description: "Would you like to know which plugins i have worked with for the front end? click here!",
        imageUrl: reactImage,
        url: "./front",
        xs: 12,
        md: 6,
        maxImageHeight:"259px",
    }, {
        title: "Node",
        description: "Would you like to know which plugins i have worked with for the back end? click here!",
        imageUrl: nodeImage,
        url: "./back",
        xs: 12,
        md: 6,
        maxImageHeight:"259px",
    },
    ]


    return (<Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", p: 2 }}>
        <Box sx={{ width: "100%",maxWidth:"1600px", textAlign: "center", padding: { lg: 7, xs: 1, } }}>
            <Typography sx={{ paddingBottom: "30px" }}>Technologies i work with</Typography>
            <Grid container spacing={3} >


                {mainTechnologies.map(item => <TechCard  title={item.title} description={item.description} imageUrl={item.imageUrl} url={item.url} xs={item.xs} md={item.md} lg={item.md} maxImageHeight={item.maxImageHeight}/>)}

            </Grid>
            
            <Typography style={{ margin: "auto", paddingTop: "30px", textAlign: "justify", maxWidth:"700px" }}> Each one has its purpouse, one for the front-end and the other one for the back-end, using one exclusively is completely capable to deliver an experience on its own, but
                    using them together can bring a better and smooth user experience, click on any of them to find out which plugins i have experience with.</Typography>
        </Box>
    </Box>)
}

export default AboutWebsite