import React from 'react';
import { AdjustedBox } from './components'
import { Typography, Card, CardContent, CardMedia, CardHeader, Grid } from '@mui/material';
import AiImage from '../../images/aipage.jpg';
import SampleDashboard from '../../images/sample_dashboard.jpg';
import PortfolioCard from './portfolioCard';

const projects = [
  {
    title: 'OpenAI Hub',
    subtitle: "This project was created to demonstrate the use of OpenAI's services, with the help of a Node server and a React Project. It is a platform that provides users with the opportunity to explore the great features that OpenAI has to offer, such as GPT-Chat and Dall-e.",
    url: "https://harmonious-pithivier-5ff977.netlify.app/",
    image: AiImage,
    description: 'This project has a Node Server and a React page\n\nReact Libraries:\n\n# Axios\n# React Router\n# React Toastify\n# Redux Toolkit\n# Redux Persist\n# HCaptcha\n\nNode Libraries:\n\n# Express\n# OpenAi\n# Cloudinary\n# Mongoose',
  },
  {
    title: 'CRUD Dashboard',
    subtitle: "This project was created to demonstrate CRUD functionalities and Google Login. It can perform tasks such as filtering elements by specific property, caching data, keeping data in sync with server and database, avoiding displaying of data when data has not been loaded correctly, error handling with state management and charting.",
    url: "https://eloquent-brigadeiros-1a42bc.netlify.app/",
    image: SampleDashboard,
    description: 'This project has a Node Server and a React page\n\nReact Libraries:\n\n# Refine\n# Material UI\n# Axios\n# React Router\n# Apex Charts\n\nNode Libraries:\n\n# Express\n# Cloudinary\n# Mongoose',
  },

]


const PortfolioIndex = () => {

  return (
    <AdjustedBox>
      <Typography component="h2" variant="h4" className="animate__animated animate__backInRight portfolio_title" sx={{ fontWeight: "bold", letterSpacing: "1.3px", textAlign: "center" }}>
        Portfolio
      </Typography>
      <Typography component="p" variant="p" sx={{ pt: 4, marginX: "auto", textAlign: "center" }} className="animate__animated animate__fadeIn animate__delay-1s animate__fast">
        Here are some projects I made to showcase my skills
      </Typography>
      <Grid container sx={{ maxWidth: "1100px", pt: { xs: 4, lg: 10 } }} gap={3} className="animate__animated animate__zoomInUp animate__delay-1s animate__fast" alignItems="stretch" justifyContent="center"
        >
        {projects.map(item => <Grid item>
          <PortfolioCard {...item} />
        </Grid>)}
      </Grid>
    </AdjustedBox >);
}

export default PortfolioIndex;