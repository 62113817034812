import { put } from 'redux-saga/effects';
import * as actions from '../actions/index';
import mod from '../../axios-handlingAlternate';
import { toast } from 'react-toastify';
import {setCredentials} from '../actions/index'

export function* login(action) {
    try {
        //const response = yield (axios.get(url, authData));
        console.log(action);

        try {
            const response = yield (mod.post('/login', { ...action.data }));
            console.log(response);
            console.log(response.data);

            toast.success("You have logged in successfully!", {
                position: toast.POSITION.TOP_RIGHT
            });
            yield localStorage.setItem('jwt', 'Bearer '+response.data.token);
            yield localStorage.setItem('user', JSON.stringify(response.data.user));
            yield put(setCredentials(response.data.user,'Bearer '+response.data.token));
        }
        catch (error) {
            yield errorHandler(error);

        }
    }
    catch (error) {
        console.log("died in job");
    }
}

export function* register(action) {

    try {
        //const response = yield (mod.post('/register', { ...action.data }));
        yield toast.success("You have logged in successfully!", {
            position: toast.POSITION.TOP_RIGHT
        });

    }
    catch (error) {
        yield errorHandler(error);
    }

}

function* errorHandler(error) {
    console.log("went to error");
    console.log(error);
    if (error.message === "Network Error") {
        yield toast.error("There was an error, please try again later", {
            position: toast.POSITION.TOP_RIGHT
        });
        yield put(actions.finishRequest([]));
        return;
    }

    if (error.response.status === 500) {
        toast.error("There was an error, please try again later", {
            position: toast.POSITION.TOP_RIGHT
        });
        yield put(actions.finishRequest([]));
        return;
    }

    console.log("[AXIOS HANDLING] ERROR COMUN");
    if (error.response.data.hasOwnProperty('error')) {
        toast.error(error.response.data.error, {
            position: toast.POSITION.TOP_RIGHT
        });
    }
    else {
        toast.error("There was an error, please try again later", {
            position: toast.POSITION.TOP_RIGHT
        });
    }
    return;

}