import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Link, useNavigate } from "react-router-dom";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/index';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ToastContainer, toast } from 'react-toastify';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" to="#" >
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const SignIn = (props) => {

  const history = useNavigate();

  React.useEffect(() => {
    if (props.user !== "") {
      history("/portfolio/main");
      return (<div></div>)
    }
  }, [props.user]);


/*
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get('email'),
      password: data.get('password'),
    });
  };
*/

  const formik = useFormik({

    initialValues: {
      email: '',
      password: '',
    },

    validationSchema: Yup.object({
      email: Yup.string()
        .email()
        .required('This field must not be empty'),
      password: Yup.string()
        .min(3, 'Must be at least 3 characters long')
        .required('This field must not be empty'),

    }),

    onSubmit: values => {

      if (values.email && values.password) {
        // PostData(values);
        props.onLogin({ email: values.email, password: values.password });
      }



      else {
        toast.error("There is an error with data", {
          position: toast.POSITION.TOP_RIGHT
        });
      }

    },
  });

  return (


    <React.Fragment>
      <ToastContainer />
      <CssBaseline />
      <Grid container style={{ height: "100%" }} justifyContent="center"
        alignItems="center">
        <Grid item xs={11}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}

          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <Box component="form" onSubmit={formik.handleSubmit} noValidate sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                name="email"
                type="text"
                variant="outlined"
                label="Email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                error={formik.touched.email && formik.errors.email ? true : false}
                helperText={formik.touched.email && formik.errors.email ?
                  formik.errors.email
                  : null}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
                error={formik.touched.password && formik.errors.password ? true : false}
                helperText={formik.touched.password && formik.errors.password ?
                  formik.errors.password
                  : null}
              />
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign In
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link to="#" variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  <Link to="/register" variant="body2">
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>
              </Grid>
            </Box>
            <Copyright sx={{ mt: 8, mb: 4 }} />
          </Box>
        </Grid>
      </Grid>

    </React.Fragment>
  );
}


const mapStateToProps = state => {
  return {
    type: state.request.responseType,
    user: state.auth.user
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onLogin: (message) => { dispatch(actions.startLogin(message)); },
  };
};



export default connect(mapStateToProps, mapDispatchToProps)(SignIn);