import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {useNavigate} from 'react-router-dom';

export default function AlertDialog(props) {
    const history = useNavigate();

  
    const handleClose = () => {
      props.setopen(false);
    };
  
    return (
      <div>




        <Dialog
          open={props.open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Would you like to access to my demo?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" style={{textAlign:"justify"}}>
              This action will take you to a demo part of the website, which is used to showcase some technology i have experience with, would you like to proceed?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() =>{history('/portfolio')}} autoFocus>Confirm</Button>
            <Button onClick={handleClose} style={{color:"red"}}>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
