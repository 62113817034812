import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import './home.css';
import {Paper} from '@mui/material';


/*style={{display:"inline-block", animation:`bounceIn 1s 1s forwards`,}}*/

export default function BasicCard(props) {
  return (
    <Paper elevation={0} sx={{backgroundColor:"secondary",height:"100%"}}  >
    <Card sx={{ minWidth: 275,height:"100%" }} >
      <CardContent style={{height:"100%"}}>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
        <img style={{maxHeight:"40px"}} src={props.icon}/>
        </Typography>
        <Typography variant="h5" component="div">
          {props.title}
        </Typography>

        <Typography variant="body2">
        
          <br />
          {props.text}
        </Typography>
      </CardContent>

    </Card>
    </Paper>
  );
}