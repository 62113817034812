import './index.css';
import React from 'react';

const AnimatedLetters = ({ letterClass, strArray, idx, animation,opacityTime, delayAnimation }) => {
  const [opacity,setOpacity] = React.useState(0);

  React.useEffect(()=>{
    const timeout = setTimeout(()=>{
      setOpacity(1);
    },opacityTime*1000);

    return () => clearTimeout(timeout);
  },[])
  return (
    <span>
      {strArray.map((char, i) => (
        <span  key={char + i} style={{display:"inline-block", animation:`${animation} 1s ${delayAnimation+i/10}s forwards`,opacity:opacity,minWidth:"13px"}} className={`${letterClass} _${i + idx}`}>
          {char}
        </span>
      ))}
    </span>
  )
}

export default AnimatedLetters
