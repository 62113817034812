
import { Grid, Card, Typography, TextField, Button, Box, } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import { useState, useRef } from 'react';
import emailjs from 'emailjs-com';
import { toast } from 'react-toastify';
import CircularProgress from '@mui/material/CircularProgress';




const ResetPassword = (props) => {

    const SignupForm = () => {
        const [IsHcaptchaCompleted, setIsHcaptchaCompleted] = useState(false);
        const [isSubmitted, setIsSubmitted] = useState(false);
        const [seed, setSeed] = useState(1);
        const form = useRef();


        const sendEmail = (formData) => {

            emailjs.sendForm(process.env.REACT_APP_EMAILJS_SERVICE_ID, process.env.REACT_APP_EMAILJS_TEMPLATE_ID, formData, process.env.REACT_APP_EMAILJS_PUBLIC_KEY)
                .then((result) => {
                    console.log(result.text);
                    setIsSubmitted(false);
                    toast.success("Your email has been sended!", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }, (error) => {
                    console.log(error.text);
                    setIsSubmitted(false);
                    toast.error("There is an error with server, please try again later!", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                });
        };





        const formik = useFormik({

            initialValues: {
                name: '',
                email: '',
                message: '',
            },

            validationSchema: Yup.object({
                name: Yup.string()
                    .min(3, 'Must be at least 3 characters long')
                    .max(15, 'Must be 15 characters or less')
                    .required('Required'),
                email: Yup.string()

                    .email()
                    .required('Required'),
                message: Yup.string()
                    .min(3, 'Must be at least 3 characters long')
                    .required('Required'),
            }),

            onSubmit: values => {

                if (!IsHcaptchaCompleted) {
                    return toast.error("Please complete the captcha", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }

                if (values.name && values.email && values.message) {
                    setIsHcaptchaCompleted(false);

                    setIsSubmitted(true);
                    sendEmail(form.current);
                    setSeed(seed + 1);
                }
                else {
                    toast.error("There is an error with data", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }

            },
        });

        return (

            <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': { m: 1, width: '25ch' },
                }}
                ref={form}
                onSubmit={formik.handleSubmit}
            >

                <TextField
                    style={{ width: "100%" }}
                    id="name"
                    name="name"
                    color="secondary"
                    type="text"
                    variant="outlined"
                    label="Name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name}
                    required
                    error={formik.touched.name && formik.errors.name ? true : false}
                    helperText={formik.touched.name && formik.errors.name ?
                        formik.errors.name
                        : null}
                />

                <TextField
                    style={{ width: "100%" }}
                    id="email"
                    color="secondary"
                    name="email"
                    type="text"
                    variant="outlined"
                    label="Email"
                    required
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    error={formik.touched.email && formik.errors.email ? true : false}
                    helperText={formik.touched.email && formik.errors.email ?
                        formik.errors.email
                        : null}
                />

                <TextField
                    style={{ width: "100%" }}
                    id="message"
                    name="message"
                    type="textarea"
                    color="secondary"
                    variant="outlined"
                    label="Message"
                    placeholder="Enter message"
                    required
                    multiline
                    minRows={6}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.message}
                    error={formik.touched.message && formik.errors.message ? true : false}
                    helperText={formik.touched.message && formik.errors.message ?
                        formik.errors.message
                        : null}
                />

                <HCaptcha key={seed} style={{ margin: 'auto' }}
                    sitekey={process.env.REACT_APP_HCAPTCHA_SITEKEY}
                    onVerify={() => { setIsHcaptchaCompleted(true) }}
                />
                {isSubmitted ? <CircularProgress sx={{ color: "#03d3fc" }} /> : <Button disabled={props.loading === true} type="submit" variant="contained" color="primary" style={{ width: "50%", marginLeft: "5px", marginTop: "12px", backgroundColor: "#64b5f6" }}>Send Now</Button>}

            </Box>

        );

    };


    return (
        <div style={{ textAlign: "center", margin: "auto" }}>
            <Grid container justifyContent="center">
                <Grid item xs={11} sm={8}>

                    <Typography variant="h4" component="h3" sx={{ margin: "auto", textAlign: "center", padding: "15px" }}>
                        Contact
                    </Typography>

                    <Typography variant="h6" component="h5" sx={{ margin: "auto", textAlign: "center", padding: "15px", fontSize: "17px" }}>
                        Interested in hiring me for your project or just want to say hi? You can fill in the contact form below or send me an email to jasonaar@hotmail.com
                    </Typography>

                    <Typography variant="h6" component="h5" sx={{ margin: "auto", textAlign: "center", padding: "15px", fontSize: "17px" }}>

                    </Typography>




                </Grid>
                <Grid xs={11} sm={8} item style={{ textAlign: "center", marginTop: "4px" }}>
                    <Card className="auth-card" style={{ margin: "15px auto", padding: "30px" }} sx={{ boxShadow: 5 }}>
                        <Typography variant="h3" component="div" className="navbar">
                            Get in touch
                        </Typography>
                        {SignupForm()}
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
}



const mapStateToProps = state => {
    return {
        type: state.request.responseType,
        data: state.request.data,
        loading: state.request.loading,
        status: state.request.status,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onSendMessage: (message) => { dispatch(actions.sendRequest('/sendmessage', 'POST', message)); },
    };
};



export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);