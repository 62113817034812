export const INIT_SET_THEME = "INIT_SET_THEME";
export const SET_THEME = "SET_THEME";
export const VERIFY_USER_THEME = "VERIFY_USER_THEME";


export const SET_DISPLAY_ADVISORY_MODAL = "SET_DISPLAY_ADVISORY_MODAL";
export const VERIFY_DISPLAY_ADVISORY_MODAL = "VERIFY_DISPLAY_ADVISORY_MODAL";


export const AUTH_SELECT_OPTION = 'AUTH_SELECT_OPTION';
export const AUTH_START = 'AUTH_START';

export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_INITIATE_LOGOUT = 'AUTH_INITIATE_LOGOUT';
export const AUTH_CHECK_TIMEOUT = 'AUTH_CHECK_TIMEOUT';
export const AUTH_USER = "AUTH_USER";
export const AUTH_CHECK_STATE = "AUTH_CHECK_STATE";
export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH';

export const SET_ALERT = 'SET_ALERT';
export const INIT_ALERT = 'INIT_ALERT';
export const CLEAR_ALERT = 'CLEAR_ALERT';
export const SET_REDIRECT_URL = 'SET_REDIRECT_URL';

export const SEND_REQUEST = 'SEND_REQUEST';
export const INIT_REQUEST = 'INIT_REQUEST';
export const PROCESS_REQUEST = 'PROCESS_REQUEST';
export const FINISH_REQUEST = 'FINISH_REQUEST';
export const SET_IMAGE_TO_EDIT = 'SET_IMAGE_TO_EDIT';

export const AUTH_REGISTER = 'AUTH_REGISTER';
export const START_LOGIN = 'START_LOGIN';
export const SET_CREDENTIALS = 'SET_CREDENTIALS'
export const AUTH_LOGOUT = 'AUTH_LOGOUT'



export const SET_UNSPLASH_CREDENTIALS = 'SET_UNSPLASH_CREDENTIALS';

export const SEARCH_UNSPLASH_LOGGED_IN = 'SEARCH_UNSPLASH_LOGGED_IN';

export const RECEIVE_UNSPLASH_AUTH_TOKEN ='RECEIVE_UNSPLASH_AUTH_TOKEN';
export const SET_UNSPLASH_AUTH_TOKEN = 'SET_UNSPLASH_AUTH_TOKEN';
export const CHECK_STORAGE_UNSPLASH_AUTH_TOKEN = 'CHECK_STORAGE_UNSPLASH_AUTH_TOKEN';

export const OBTAIN_USER_DATA = 'OBTAIN_USER_DATA';
export const SET_UNSPLASH_USERNAME = 'SET_UNSPLASH_USERNAME';

export const INIT_UNSPLASH_REQUEST = 'INIT_UNSPLASH_REQUEST';

export const LIKE_IMAGE = 'LIKE_IMAGE';