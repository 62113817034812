import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    theme:'light',

};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_THEME: {console.log(action.theme); return updateObject(state,{theme:action.theme})}
        default:
            return state;
    }
};

export default reducer;