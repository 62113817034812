import { put } from 'redux-saga/effects';
import * as actions from '../actions/index';
import axios from 'axios';
import mod from '../../axios-handlingAlternate';
import { toast } from 'react-toastify';

export function* handleRequestAlternate(action) {

    yield put(actions.initRequest());

    if (action.method == "GET") {
        yield getRequest(action);
    }
    else if (action.method == 'POST') {

        yield postRequest(action);
    }

};

export function* login(action) {
    try {
        //const response = yield (axios.get(url, authData));
        console.log(action);

        try {
            const response = yield (mod.post('/login', { ...action.data }));
            console.log(response);
            console.log(response.data);

            toast.success("You have logged in successfully!", {
                position: toast.POSITION.TOP_RIGHT
            });
            localStorage.setItem('jwt', response.data.token);
            localStorage.setItem('user', JSON.stringify(response.data.user));

        }
        catch (error) {
            yield errorHandler(error);

        }
    }
    catch (error) {
        console.log("died in job");
    }
}

export function* register(action) {

    try {
        const response = yield (mod.post('/register', { ...action.data }));
        yield toast.success("You have logged in successfully!", {
            position: toast.POSITION.TOP_RIGHT
        });
    }
    catch (error) {
        yield errorHandler(error);
    }

}

function* errorHandler(error) {
    console.log("went to error");
    console.log(error);
    if (error.message === "Network Error") {
        yield toast.error("There was an error, please try again later", {
            position: toast.POSITION.TOP_RIGHT
        });
        yield put(actions.finishRequest([]));
        return;
    }

    if (error.response.status === 500) {
        yield toast.error("There was an error, please try again later", {
            position: toast.POSITION.TOP_RIGHT
        });
        yield put(actions.finishRequest([]));
        return;
    }

    console.log("[AXIOS HANDLING] ERROR COMUN");
    if (error.response.data.hasOwnProperty('error')) {
        yield toast.error(error.response.data.error, {
            position: toast.POSITION.TOP_RIGHT
        });
    }
    else {
       yield toast.error("There was an error, please try again later", {
            position: toast.POSITION.TOP_RIGHT
        });
    }
    return;

}

export function* searchLoggedIn(action) {
    yield put(actions.initRequest());
    console.log("hello from search logged in");
    console.log(action);

    yield put(actions.processRequest());
    try {
        const response = yield (axios.post(action.url, {
            token: action.unsplashToken,
        }));
        console.log(response.data);
        yield put(actions.finishRequest(response.data));
    }
    catch (error) {
        yield (actions.finishRequest([]));
        yield errorHandler(error);
    }

}

function* getRequest(action) {

    yield put(actions.processRequest());
    try {
        console.log(mod);
        const response = yield (mod.get(action.url));
        console.log('[fool]' + response);
        console.log('[strong]' + response.data);
        yield put(actions.finishRequest(response.data));
    }
    catch (error) {
        yield (actions.finishRequest([]));
        yield errorHandler(error);
    }
}

function* postRequest(action) {

    try {
        //const response = yield (axios.get(url, authData));
        console.log(action);

        yield put(actions.processRequest());
        try {
            console.log(mod);
            const response = yield (mod.post(action.url, { ...action.data }));
            console.log('[fool]' + response);
            console.log('[strong]' + response.data);
            yield put(actions.finishRequest(response.data));
        }
        catch (error) {
            console.log("asdasd");
            yield errorHandler(error);
        }
    }

    catch (error) {
        console.log("died in job");
    }
}