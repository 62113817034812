import { useState } from 'react';
import { Typography, Card, CardContent, CardMedia, CardHeader, IconButton, Collapse } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    color: "inherit",
    border: "1px solid white",
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));


const PortfolioCard = ({ title, subtitle, description, url, image }) => {
    const [expanded, setExpanded] = useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const handleUrl = () => {
        window.open(url, '_blank', 'noopener,noreferrer');
    }

    return <Card sx={{ maxWidth: 360, p: 1, position: 'relative' }} >
        <CardMedia
            onClick={handleUrl}
            component="img"
            sx={{ cursor: "pointer" }}
            height="194"
            image={image}
            alt="Paella dish" className="grow"
        />
        <CardHeader
            title={title}
            onClick={handleUrl}
            sx={{ cursor: "pointer" }}
        />
        <CardContent>
            <Typography variant="body2" sx={{ textAlign: "justify" }}>
                {subtitle}
            </Typography>
        </CardContent>
        <div style={{ height: "50px" }}>
            <ExpandMore
                style={{ position: "absolute", bottom: 5, left: 10 }}
                expand={expanded}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show more"
            >

                <ExpandMoreIcon />
            </ExpandMore>
        </div>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent>
                <pre style={{ whiteSpace: "pre-wrap", wordBreak: "break-word" }}>
                    {description}
                </pre>
            </CardContent>
        </Collapse>
    </Card >
}

export default PortfolioCard