
import {takeEvery,all} from 'redux-saga/effects';
import * as actionTypes from '../actions/actionTypes';
import {handleRequestAlternate} from './requestsSagaAlternate';
import { login,register } from './authSaga';



export function* watchRequests() {
    yield all([
        //takeEvery(actionTypes.SEND_REQUEST,handleRequest),
        takeEvery(actionTypes.SEND_REQUEST,handleRequestAlternate),
        takeEvery(actionTypes.START_LOGIN,login),
        takeEvery(actionTypes.AUTH_REGISTER,register),
    ]);
}
