import Axios from 'axios';
import * as actions from './store/actions/index';


    const monos =  Axios.create({
        baseURL: 'http://localhost:5000/',
      });

      monos.defaults.baseURL = 'http://localhost:5000/';  //BASE URL
    
        monos.interceptors.request.use(request =>{
        const token = localStorage.getItem('jwt');
        const unsplashToken = localStorage.getItem('unsplash_credentials');
        console.log(token);
        if(token === '' || token === null)
        {
            console.log("[AXIOS HANDLING] request made");
          //  eres.onClearAlert();
            //Edit request
            return request;
        }
        if(unsplashToken === '' || unsplashToken === null)
        
        console.log("[AXIOS HANDLING] request made logged in");
            request.headers.Authorization = token;
            request.headers.Proxy_Authorization = unsplashToken;
            //eres.onClearAlert();
            //Edit request
            return request;

    },error =>{
        console.log("[AXIOS HANDLING]  [REQUEST ERROR]");
        console.log(error);
        return Promise.reject(error);
    });


    monos.interceptors.response.use(response => {
        console.log("[AXIOS HANDLING]  [RESPONSE RECEIVED]");
        console.log(response);
        //Edit request
        return response;
    },error =>{
        console.log(error.message);
        return Promise.reject(error);
    });



export default monos;