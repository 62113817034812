import * as actionTypes from './actionTypes';

export const sendRequest = (url,method,data=[]) => {
    return {
        type: actionTypes.SEND_REQUEST,
        url: url,
        method:method,
        data: data,
    };
};

export const initRequest = () =>{
    return {
        type: actionTypes.INIT_REQUEST,
    }
}


export const processRequest = () =>{
    return{
        type:actionTypes.PROCESS_REQUEST,
    }
}

export const finishRequest = (action) =>{
    return{
        type:actionTypes.FINISH_REQUEST,
        data:action,
    }
}

export const startLogin = (action) =>{
    return{
        type:actionTypes.START_LOGIN,
        data:action,
    }
}

export const startRegister = (action) =>{
    return{
        type:actionTypes.AUTH_REGISTER,
        data:action
    }
}
