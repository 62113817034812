
import { Route, Routes } from 'react-router-dom';
import Home from './pages/home/home';
import Contact from './pages/contact/contact';
import Layout from './hoc/layout';
import { useEffect } from 'react';
import { connect } from 'react-redux';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import Login from './pages/portfolio/login/login';
import Register from './pages/portfolio/register/register'

import * as actions from './store/actions/index';

import AboutThisSite from './pages/aboutinfo/about';
import AboutFrontEnd from './pages/aboutinfo/aboutReact';
import AboutBackEnd from './pages/aboutinfo/aboutNode';
import { themeInitial, themeDark } from './styles/theme';
import NoMatch from './pages/404/NoFound';
import PortfolioIndex from './pages/PortfolioIndex/Index';
import './index.css';
/*
import DashboardLayout from './hoc/dashboardLayout/decider';
import IndexSecondPage from './pages/ModernGPT/App.jsx';
import MainPortfolioPage from './pages/portfolio/MainPage/Index';

import PortfolioIndex from './pages/PortfolioIndex/Index';
import ImageEditor from './pages/portfolio/ImageEditor/ImageEditor';
import UnsplashTokenHandler from './pages/portfolio/MainPage/UnsplashTokenHandler';
import LikedImages from './pages/portfolio/MainPage/likedImages';
import PortfolioMainPage from './pages/portfolio/MainPage/MainPage';
*/

function App(props) {
  console.log(props);
  const pageTheme = props.theme === "light" ? themeInitial : themeDark;
  useEffect(() => {
    const token = localStorage.getItem('jwt');
    const user = localStorage.getItem('user');
    const unsplashAuth = localStorage.getItem('unsplash_credentials');
    const unsplashUsername = localStorage.getItem('unsplash_username');
    const actualTheme = localStorage.getItem("theme");
    if ((token !== '' && token !== null)) {
      props.onSetCredentials(user, token);
    }
    if (unsplashAuth !== '' && unsplashAuth !== null) {
      console.log(unsplashUsername)
      props.onSetUnsplashCredentials(unsplashAuth, unsplashUsername);
    }

    console.log("actualtheme:", actualTheme);

    if (actualTheme === "dark" || actualTheme === null || actualTheme === "") {
      console.log("theme is dark");
      props.onSetTheme("light");
    }

    console.log("porttrait");





  }, []);

  /*
          <Route path="/portfolio/main" element={<DashboardLayout><MainPortfolioPage /></DashboardLayout>} />
          <Route path="/portfolio/search" element={<DashboardLayout><PortfolioMainPage /></DashboardLayout>} />
          <Route path="/portfolio/editor" element={<DashboardLayout><ImageEditor /></DashboardLayout>} />
          <Route path="/portfolio/handleunsplash" element={<DashboardLayout><UnsplashTokenHandler /></DashboardLayout>} />
          <Route path="/portfolio/likedimages" element={<DashboardLayout><LikedImages /></DashboardLayout>} />
          <Route path="/portfolio/gpt" element={<IndexSecondPage />} />
          <Route path="/portfolio/indes" element={<PortfolioIndex />} />
  */


  return (
    <ThemeProvider theme={pageTheme}>
      <Routes>
        <Route path="/" element={<Layout><Home /></Layout>} />
        <Route path="/contact" element={<Layout><Contact /></Layout>} />
        <Route path="/about" element={<Layout><AboutThisSite /></Layout>} />
        <Route path="/about/front" element={<Layout><AboutFrontEnd /></Layout>} />
        <Route path="/about/back" element={<Layout><AboutBackEnd /></Layout>} />
        <Route path="/portfolio" element={<Login />} />
        <Route path="/index" element={<Layout><PortfolioIndex /></Layout>} />
        <Route path="/register" element={<Register />} />



        <Route path="*" element={<NoMatch />} />
      </Routes>
    </ThemeProvider>
  );
}


const mapDispatchToProps = dispatch => {
  return {
    onSetCredentials: (user, token) => dispatch(actions.setCredentials(user, token)),
    onSetUnsplashCredentials: (code, username) => dispatch(actions.setUnsplashCredentials(code, username)),
    onSetTheme: (theme) => dispatch(actions.setTheme(theme)),
  };
};

const mapStateToProps = state => {
  return {
    theme: state.misc.theme,
  };
};



export default connect(mapStateToProps, mapDispatchToProps)(App);
