import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';
import { toast } from 'react-toastify';

const initialState = {
    responseType: null,
    data: [],
    loading: false,
    status: null,
};

const initRequest = (state) => {
    return updateObject(state, { loading: true, status: 'initialized' });
}

const finishRequest = (state, action) => {
    if (action.data.hasOwnProperty('message')) {
        toast.success(action.data.message, {
            position: toast.POSITION.TOP_RIGHT
        });
    }
    
    if (Array.isArray(action.data)) {
        if (action.data.length < 1) {
            return updateObject(state, { loading: false, status: 'finished' });
        }
    }
    return updateObject(state, { data: action.data, loading: false, status: 'finished' });

}


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.INIT_REQUEST: return initRequest(state);
        case actionTypes.PROCESS_REQUEST: return updateObject(state, { status: 'processing' })
        case actionTypes.FINISH_REQUEST: return finishRequest(state, action);
        default:
            return state;
    }
};

export default reducer;


