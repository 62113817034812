import * as actionTypes from './actionTypes';

export const setTheme = (theme) =>{
    console.log("theme:", theme);
    if(theme === "light"){
        localStorage.setItem('theme',"dark");
        return {
            type:actionTypes.SET_THEME,
            theme:"dark",
        };
    }
    else {
        localStorage.setItem('theme',"light");
        return {
            type:actionTypes.SET_THEME,
            theme:"light",
        };
    }

}

