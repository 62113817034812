import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    user:'',
    token: '',
    urlEditor:'',
    unsplashtoken:'',
    username:'',

};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_CREDENTIALS: return updateObject(state,{user:action.user,token:action.token})
        case actionTypes.AUTH_LOGOUT: return updateObject(state,{user:'',token:''});
        case actionTypes.SET_IMAGE_TO_EDIT: return updateObject(state,{urlEditor:action.imageUrl})
        case actionTypes.SET_UNSPLASH_CREDENTIALS : return updateObject(state,{unsplashtoken:action.code,username:action.username});
        default:
            return state;
    }
};

export default reducer;