import React from "react";
import Navbar from "../UI/navbar";
import CssBaseline from "@mui/material/CssBaseline";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MobileNav from "../UI/mobilenav";
import * as actions from '../store/actions/index';
import {
  Box,
  Grid,
  Paper,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Profiles from "../UI/profiles";
import { connect } from "react-redux";

const Layout = (props) => {
  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.down("lg"));


  return <React.Fragment>
    <CssBaseline />

    <main style={{ minHeight: "100%", display: "flex", backgroundColor:theme.palette.mode === "dark" ? "#f7f7f7" : "inherit" }}>
      <ToastContainer />
      {
        matches
          ? <Box style={{ width: "100%" }} >
            <MobileNav switchStatus={props.theme} switchTheme={props.onSetTheme} />
            <Box sx={{ flexGrow: 1 }}>
              {props.children}
            </Box>
          </Box>
          : <Box sx={{ display: "flex", width: "100%" }}>
            <Profiles switchStatus={props.theme} switchTheme={props.onSetTheme} />
            <Box sx={{ flexGrow: 1, display: "flex" }}>
              {props.children}
            </Box>
          </Box>
      }
    </main>
  </React.Fragment>;
};


const mapStateToProps = state => {
  return {
    theme: state.misc.theme,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onSetTheme: (theme) => dispatch(actions.setTheme(theme))
    ,
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(Layout);


