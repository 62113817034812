import * as actionTypes from './actionTypes';

export const setCredentials = (user,token) => {
    console.log("setted");
    return {
        type: actionTypes.SET_CREDENTIALS,
        user: user,
        token: token
    };
};

export const logout = () =>{
    return {
        type:actionTypes.AUTH_LOGOUT
    };
}

export const setUnsplashCredentials = (code,username) =>{
    return{
        type:actionTypes.SET_UNSPLASH_CREDENTIALS,
        code:code,
        username:username,

    };
}