import { Box } from '@mui/material';
import { styled } from '@mui/system';

const AdjustedBox = styled(Box)(({ theme }) => ({
    display: "flex",
    maxWidth: "1200px",
    marginLeft: "auto",
    marginRight: "auto",
    flexDirection:"column",
    padding:"30px",
    justifyContent: "center",

}));



export { AdjustedBox };