import { createTheme } from "@mui/material"


export const themeInitial = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#E6E6E6',
      contrastText: '#333C4D',
    },
    secondary: {
      main: '#66CC8A',
    },
    background: {
      default: '#FFFFFF',
      paper: '#E6E6E6',
    },
    text: {
      primary: '#1F2937',
      secondary: 'rgba(247,30,30,0.54)',
      disabled: '#66CC8A',
    },
  },
});


export const themeDark = createTheme({
    palette: {
        type: 'dark',
        primary: {
          main: '#212121',
        },
        secondary: {
          main: '#f50057',
        },
        background: {
          default: '#101624',
          paper: '#1D283A',
        },
        text: {
          primary: '#EEEEEE',
          secondary: '#B3C5EF',
        },
      },
  });
