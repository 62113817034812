import React from 'react';
import { Grid, Box, Typography, } from '@mui/material';
import TechCard from './techCard';
import ExpressIcon from '../../images/express.png';
import MongooseIcon from '../../images/mongoose.png';
import ExpressValidatorIcon from '../../images/validator.svg';
import NodeMailerIcon from '../../images/nodemailer.png';
import BcryptIcon from '../../images/bcrypt.png';
import JwtIcon from '../../images/jwt.png';



const AboutWebsite = () => {

    const techArray = [
        {
            name: "Express",
            description: "Node.js framework which aids in development of web and mobile applications.",
            image: ExpressIcon,
            url:'',
            xs:12,
            lg:4,
            md:6,
            tab:"https://expressjs.com/"
        },
        {
            name: "Mongoose",
            description: "Provides convenience in the creation and management of data in MongoDB.",
            image: MongooseIcon,
            url:'',
            xs:12,
            lg:4,
            md:6,
            tab:"https://mongoosejs.com/",
        },
        {
            name: "Express Validator",
            description: "Expressjs middlewares that wraps validator.js validator and sanitizer functions.",
            image: ExpressValidatorIcon,
            url:'',
            xs:12,
            lg:4,
            md:6,
            tab:'https://express-validator.github.io/'
        },
        {
            name: "Nodemailer",
            description: "Facilitates sending emails from a server with ease.",
            image: NodeMailerIcon,
            url:'',
            xs:12,
            lg:4,
            md:6,
            tab:"https://nodemailer.com/",
        },
        {
            name: "Bcrypt",
            description: "Allows building a password security platform.",
            image: BcryptIcon,
            url:'',
            xs:12,
            lg:4,
            md:6,
            tab:"https://github.com/kelektiv/node.bcrypt.js",
        },
        
        {
            name: "Jsonwebtoken",
            description: "Permits securely transmitting information between parties.",
            image: JwtIcon,
            url:'',
            xs:12,
            lg:4,
            md:6,
            tab:"https://github.com/auth0/node-jsonwebtoken",
        },
    ];






    return (<Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", p: 2 }}>
        <Box sx={{ width: "100%",maxWidth:"1800px", textAlign: "center", padding: { lg: 7, xs: 1, } }}>
            <Typography sx={{ paddingBottom: "30px" }}>You can click on any of them to know more about</Typography>
            <Grid container spacing={3}>


                {techArray.map(item => <TechCard title={item.name} description={item.description} imageUrl={item.image} url={item.url} xs={item.xs} md={item.md} lg={item.lg} maxImageHeight="150px" newTab={item.tab}/>)}


            </Grid>
            <Typography style={{ margin: "auto", paddingTop: "30px", maxWidth: "700px", textAlign: "left" }}> </Typography>
        </Box>
    </Box>)
}

export default AboutWebsite